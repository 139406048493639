import './Loading.css';

import { useState, useEffect, useContext } from 'react';
import {Context} from '../Context';

// Loading Images
import KysonIcon from "../Resources/Images/Logo/KysonIconInverse.png";
import KysonDesignsText from "../Resources/Images/Logo/KysonDesignsTextInverse.png"

// Loading Control Settings
const changeFrequency = 10;
const initialWindowHeight = 100;
const windowHeightChange = 3;
const initialMobileLogoWidth = 50;
const initialDesktopLogoWidth = 15;
const mobileLogoWidthMin = 10;
const desktopLogoWidthMin = 10;
const mobilelogoChange = 1;
const desktopLogoChange = 0.1;
const desktopWindowChange = 3;
const textCutoffHeight = 90;
const mobileHeightMin = 15;
const desktopHeightMin = 15;
const initialLoadingDisplayWidth = 100;
const desktopMinWindowWidth = 10;

// Loading Window and Transition
const Loading = ({desktop=false}) => {
    // Context for application state
    const contextObject = useContext(Context);
    // States for window & logo size
    const [loadingHeight, setLoadingHeight] = useState(initialWindowHeight);
    const [logoWidth, setLogoWidth] = useState();
    const [logoWindowWidth, setLogoWindowWidth] = useState(initialLoadingDisplayWidth);

    useEffect(() => {
        if(desktop){
            setLogoWidth(initialDesktopLogoWidth);
        }
        else{
            setLogoWidth(initialMobileLogoWidth);
        }
    },[]);

    // Handles transition 
    const handleShrinkingLoad = (height,logoWidth,windowWidth) => {
        // Desktop
        if(desktop){
            if(height<desktopHeightMin){
                contextObject.setReady(false);
                contextObject.setLoading(false);
            }
            else{
                const newHeight = height-windowHeightChange;
                setLoadingHeight(newHeight)
        
                let newLogoWidth = logoWidth;
                if(logoWidth > desktopLogoWidthMin){
                    newLogoWidth = newLogoWidth-desktopLogoChange;
                    setLogoWidth(newLogoWidth);
                }

                let newWindowWidth = windowWidth
                if( newWindowWidth > desktopMinWindowWidth){
                    newWindowWidth = newWindowWidth-desktopWindowChange
                    setLogoWindowWidth(newWindowWidth);
                }
        
                setTimeout(() => {
                    handleShrinkingLoad(newHeight,newLogoWidth,newWindowWidth);
                }, changeFrequency)
            }
        }
        // Mobile
        else{
            if(height<mobileHeightMin){
                contextObject.setReady(false);
                contextObject.setLoading(false);
            }
            else{
                const newHeight = height-windowHeightChange;
                setLoadingHeight(newHeight)
        
                let newLogoWidth = logoWidth;
                if(logoWidth > mobileLogoWidthMin){
                    newLogoWidth = newLogoWidth-mobilelogoChange;
                    setLogoWidth(newLogoWidth);
                }
        
                setTimeout(() => {
                    handleShrinkingLoad(newHeight,newLogoWidth,logoWindowWidth);
                }, changeFrequency)
            }
        }
       

    }

    // Trigger to transition from Main
    useEffect(() => {
        if(contextObject.ready) {
            handleShrinkingLoad(loadingHeight,logoWidth,logoWindowWidth);
        }
    }, [contextObject.ready]); // eslint-disable-line react-hooks/exhaustive-deps

    // CSS Properties for Loading
    const loadingCSS = {
        "--loadingWindowHeight": loadingHeight+'%',
        "--LogoWidth": logoWidth+'%',
        "--LogoWindowWidth": logoWindowWidth+'%',
    }

    // Renders Loading Screen
    return(
        <div className='LoadingWindow' style={loadingCSS}>
            <div className='LoadingDisplay'>

                <img className='LoadingLogo' src={KysonIcon} alt={'Kyson Logo'}/>

                { loadingHeight >= textCutoffHeight &&
                    <img className='LoadingName' src={KysonDesignsText} alt={'Kyson Name'}/>
                }

            </div>
        </div>
    )
}

export default Loading;