import './DesktopHome.css';
import {Context} from '../Context';
import { useContext, useEffect, useRef, useState } from 'react';

const changeEngagementFrequency = 5000;

const serverPath = 'http://kysondesigns.com/'

const ProjectRecord = ({introRecord,id,selectedRecord,selectRecord}) => {
    const [ recordSize, setRecordSize ] = useState();

    const [selected, setSelected] = useState(false);

    useEffect(() => {
        if(selectedRecord === id) {
            setRecordSize(60);
            setSelected(true);
        }
        else {
            setRecordSize(10);
            setSelected(false);
        }
    },[selectedRecord]);

    const projectRecordCssProps = {
        '--record-width': recordSize+'vw',
    };

    const handleHover = () => {
        selectRecord(id);
    };

    return(
        <div className='Desktop-Intro-Engagement-Record' style={projectRecordCssProps} onMouseOver={() => handleHover()}>
            <img src={serverPath+introRecord.image} className='Desktop-Intro-Engagement-Record-Image'/>
            {   selected &&
            <div className='Desktop-Intro-Engagement-Record-Text'>
                <div className='Desktop-Intro-Engagement-Record-Tag'>
                    {introRecord.tag}
                </div>
                <div className='Desktop-Intro-Engagement-Record-Title'>
                    {introRecord.title}
                </div>
                <div className='Desktop-Intro-Engagement-Record-Description'>
                    {introRecord.description}
                </div>
            </div>
        }
        </div>

    )
};

const DesktopIntro = ({introList}) => {
    const [ cycling, setCycling ] = useState(true);
    const cyclingRef = useRef(cycling);

    const [ selectedRecord, setSelectedRecord ] = useState(0);
    const selectedId = useRef(selectedRecord);

    const [ delayedTimerId, setDelayedTimerId ] = useState();

    useEffect(() => {
        selectedId.current = selectedRecord;
    },[selectedRecord]);

    useEffect(() => {
        cyclingRef.current = cycling;
    },[cycling]);

    const clearDelayedTimer = () => {
        clearTimeout(delayedTimerId)
    };

    const selectRecord = (id) => {
        setCycling(false);
        clearDelayedTimer();
        setSelectedRecord(id);
        
        const delayedTimer = setTimeout(() => {
            setCycling(true);
        },2000);

        setDelayedTimerId(delayedTimer);
    };

    const makeCycleSelection = () => {
        let newSelection = selectedId.current 
        if(cyclingRef.current){
            newSelection = newSelection + 1;
            if(newSelection > 4) newSelection=0;
            setSelectedRecord(newSelection);
        }

        return newSelection;
    };

    const startCycling = () => {
        setTimeout(() => {
            const newSelection = makeCycleSelection();
            startCycling(newSelection);
        },changeEngagementFrequency);

    };

    useEffect(() => {
        startCycling();
    },[]);

    return(
        <div className='Desktop-Intro-Engagement'>
            {  introList && 
                introList.map((introRecord, key) => (
                    <ProjectRecord introRecord={introRecord} key={key} id={key} selectedRecord={selectedRecord} selectRecord={selectRecord}/>
                )) 
            }
        </div>
    )
};

const DelayedPresent = ({PassthroughFunction}) => {
    return(
        <>
        <PassthroughFunction />
        </>
    )
};

const ConnectButton = () => {
    return(
        <div className='Desktop-Engagement-Connect'>
            Desktop Version coming Soon. Visit us on mobile.
        </div>
    )
};

const DesktopEngagment = () => {
    return(
        <div className='Desktop-Engagement'>
            <DelayedPresent PassthroughFunction={ConnectButton}/>

        </div>
    )
};

const DesktopHome = ({}) => {
    const contextObject = useContext(Context);

    return(
        <div className='Desktop-Home'>
            <DesktopIntro introList={contextObject.introList}/>
            <DesktopEngagment />
        </div>
    )
};

export default DesktopHome;