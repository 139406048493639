import './DesktopCommercial.css';

const DesktopCommercial = () => {
    return(
        <div className='Desktop-Commercial'>

        </div>
    )
};

export default DesktopCommercial;