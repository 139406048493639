import './MobileConnect.css';

import IntroImage from '../Resources/Images/Connect/Intro.jpg';
import SubmittedImage from '../Resources/Images/Connect/Submitted.jpg';
import { FaStar } from "react-icons/fa";

// Footer
import MobileFooter from '../Footer/MobileFooter';
import { useEffect, useState, useContext } from 'react';
import {Context} from '../Context';

const IntroConnect = () => {
    return (
        <div className='IntroConnect'>
            <div className='IntroImageFrame'>
                <img src={IntroImage} alt='Connect Intro' className='IntroConnectImage'/>
            </div>

            <div className='IntroContent'>
                <div className='IntroConnectTitle'>
                    Let's Connect
                </div>

            </div>

            
        </div>
    )
};

// CREATE AN ARRAY OF INPUTS
const ConnectForm = ({setAlreadySubmitted}) => {
    const contextObject = useContext(Context);
    
    const [firstName, setFirstName] = useState('');
    const [flagfirstName, setFlagFirstName] = useState(false);
    const [lastName, setLastName] = useState('');
    const [flagLastName, setFlagLastName] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [flagEmail, setFlagEmail] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [countryMenu, setCountryMenu] = useState(false);
    const [zipCode, setZipCode] = useState('');
    const [flagZip, setFlagZip] = useState(false);
    
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [flagMessage, setFlagMessage] = useState(false);


    const errorMessage = 'Please enter all required fields';
    const [submitFlag, setSubmitFlag] = useState(false);
    const [submitError, setSubmitError] = useState(errorMessage);

    useEffect(() => {
        setSubmitFlag(false);
        if(firstName !== '') setFlagFirstName(false);
        if(lastName !== '') setFlagLastName(false);
        if(emailAddress !== '') setFlagEmail(false);
        if(message !== '') setFlagMessage(false);
        if(zipCode !== '') setFlagZip(false);

    },[firstName,lastName,emailAddress,message, zipCode])

    const formArray = [
        {
            title: 'First Name',
            required: true,
            type: 'input',
            options: ["I'd like to speak with a specialist", "I have a project", "I have a question", "I have a media inquiry"],
            setInput: setFirstName,
         },
         {
            title: 'Last Name',
            required: true,
            type: 'input',
            setInput: setLastName,
        },
        {
            title: 'Email Address',
            required: true,
            type: 'input',
            setInput: setEmailAddress,
        },
        {
            title: 'Company Name',
            required: false,
            type: 'input',
            setInput: setCompanyName,
        },
        {
            title: 'Job Title',
            required: false,
            type: 'input',
            setInput: setJobTitle,
        },
        {
            title: 'Zip Code',
            required: true,
            type: 'input',
            setInput: setZipCode,
        },
        {
            title: 'Phone Number',
            required: false,
            type: 'input',
            setInput: setPhoneNumber,
        },
        {
            title: 'Message',
            required: true,
            type: 'long',
            setInput: setMessage,
        },
    ];

    const inputToSubmit = 
    {
        firstName: firstName,
        lastName: lastName,
        emailAddress: emailAddress,
        companyName: companyName,
        jobTitle: jobTitle,
        zipCode: zipCode,
        phoneNumber: phoneNumber,
        message: message,
    };

    const checkEmail = () => {
        let failed = false
        try{
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
            if(re.test(emailAddress)){
                // Valid Email
            }
            else{
                failed = true;
            }
        }
        catch{
            failed = true;
        }

        return failed;
    };

    const checkFields = () => {
        let failed = false;

        if(firstName === '') {
            setFlagFirstName(true);
            failed = true;
        };
        if(lastName === '') {
            setFlagLastName(true);
            failed = true;
        };
        if(checkEmail()) {
            setFlagEmail(true);
            failed = true;
        };
        if(message === '') {
            setFlagMessage(true);
            failed = true;
        };
        if(zipCode.length !=5) {
            setFlagZip(true);
            failed = true;
        };

        return failed;
    };

    const submitForm = () => {
        if(checkFields()){
            setSubmitFlag(true);
            setSubmitError(errorMessage);
        }
        else{
            contextObject.submitConnect(inputToSubmit, contextObject.setError, setAlreadySubmitted);
        }
    };

    const selectCountry = (zipCode) => {
        setZipCode(zipCode);
        setCountryMenu(false);
    };

    return (
        <div className='ConnectFormWindow'>
            <div className='ConnectFormText'>
                Fill out the form below to connect with our team.
            </div>

            <div className='ConnectForm'>
                {
                    formArray.map((object, i) => {
                        return (
                            <div className='ConnectFormField' key={i}>
                                <div className='ConnectFormFieldTitlebar'>
                                    <div className='ConnectFormFieldTitle'>
                                        {object.title}
                                    </div>
                                    {   object.required &&
                                        <FaStar className='ConnectFormFieldTitleSymbol'/>
                                    }
                                </div>
                                <div className='ConnectFormFieldInput'>
                                    {   object.type === 'input' &&
                                        <input onChange={(event) => object.setInput(event.target.value)} className='ConnectFormInput'/>
                                    }
                                    {   object.type === 'selection' &&
                                        <div className='ConnectFormSelection' onClick={() => setCountryMenu(true)}>
                                            {zipCode}
                                        </div>
                                    }

                                    {   object.type === 'selection' && countryMenu &&
                                        object.options.map((option, i) => (
                                                <div key={i} onClick={() => selectCountry(option)} className='ConnectCountryOptions'>
                                                    {option}
                                                </div>
                                        ))
                                    }
                                    {   object.type === 'long' &&
                                        <textarea onChange={(event) => object.setInput(event.target.value)} className='ConnectFormLong'/>
                                    }
                                </div>
                                {   object.title === 'First Name' && flagfirstName &&
                                    <div className='ConnectFormError'>
                                        Please enter first name.
                                    </div>
                                }
                                {   object.title === 'Zip Code' && flagZip &&
                                    <div className='ConnectFormError'>
                                        Please enter a valid zip code.
                                    </div>
                                }
                                {   object.title === 'Last Name' && flagLastName &&
                                    <div className='ConnectFormError'>
                                        Please enter last name.
                                    </div>
                                }
                                {   object.title === 'Email Address' && flagEmail &&
                                    <div className='ConnectFormError'>
                                        Please enter valid email address.
                                    </div>
                                }
                                {   object.title === 'Message' && flagMessage &&
                                    <div className='ConnectFormError'>
                                        Please enter a message.
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>

            <button className='ConnectSubmit' onClick={() => submitForm()}>
                Submit
            </button>
            {   submitFlag &&
                <div className='ConnectSubmitError'>
                {submitError}
                </div>
            }
        </div>
    )
};

 const ConnectSubmitted = () => {
    const contextObject = useContext(Context);

    const navigateHome = () => {
        contextObject.navigateTo('Home');
    };

    return(
        <div className='SubmittedWindow'>
            <div className='SubmittedImageFrame'>
                <img src={SubmittedImage} alt={'Submitted'} className='SubmittedImage'/>
            </div>

            <div className='SubmittedContent'>
                <div className='SubmittedTitle'>
                    Recieved
                </div>
                <div className='SubmittedText'>
                    We look forward to speaking with you, and will be in contact shortly.
                </div>
                <div className='SubmittedLink' onClick={() => navigateHome()}>
                    Return home
                </div>
            </div>
        </div>
    )
 };

const MobileConnect = () => {
    const [alreadySubmitted, setAlreadySubmitted] = useState(false);

    return(
        <div className='MobileConnect'>

            {   alreadySubmitted &&
                <ConnectSubmitted />
            }

            {   !alreadySubmitted &&
                <>
                    <IntroConnect />
                    <ConnectForm setAlreadySubmitted={setAlreadySubmitted}/>
                    <MobileFooter connect={true}/>
                </>
            }
        </div>
    )
};

export default MobileConnect;