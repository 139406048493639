import './App.css';
import {Context} from './Context';
import {useEffect, useState} from "react";

// Import Mobile & Desktop Versions
import MobileApp from './Mobile/MobileApp';
import DesktopApp from './Desktop/DesktopApp';

import {getProjectList, getIntroList, submitConnect, submitJoin} from './Api';


// Time Initial Load Screen Displays
const changeLoadingTime = 400

const App = () => {
  // Width of App Window
  const [width, setWidth] = useState(window.innerWidth);
  // Control of Loading Window
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  // Control of Page to Display
  const [view, setView] = useState("Home");
  // Control of Mobile Menu Window
  const [menu, setMenu] = useState(false);
  const [menuClose, setMenuClose] = useState(false);

  const [projectList, setProjectList] = useState([{},{}])
  const [introList, setIntroList] = useState([{},{}])
  const [error, setError] = useState();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if(projectList.length > 2 && introList.length > 2){
      setInitialized(true);
      setReady(true);
    }
  
  }, [projectList, introList]);

  // Navigate to Page specified by Location
  const navigateTo = (location) => {
    if(menu) toggleMenu();
    if(location) setView(location);

    setLoading(true);

    setTimeout(() => {
      setReady(true);
    }, changeLoadingTime)
  };

  // Navigate to Home Page
  const goHome = () => {
    if(menu) toggleMenu();
    navigateTo('Home');
  };

  // Finalize Closing of Menu Window
  const finalizeMenuClose = () => {
    setMenu(false);
    setMenuClose(false);
  }

  // Toggle Menu Window
  const toggleMenu = () => {
    if(menu)  setMenuClose(true);
    else setMenu(true);
  };

  // SET WINDOW DIMENSIONS
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  // LISTEN FOR RESIZE OF WINDOW
  const setWindowSizeListener = () => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  };

  // INITIAL LOADING PERIOD
  useEffect(() => {
    setWindowSizeListener();
    if(!initialized) {
      getProjectList(setError, setProjectList);
      getIntroList(setError, setIntroList)
    }
  }, []);

  // LIST OF MENU ITEMS
  const menuItems = {
    "Home": "Home",
    "Residential": "Residential",
    "Commercial": "Commercial",
    "About Us": "About",
    "Connect": "Connect",
    "Login": "Login",
  };

  const loginUser = (object) => {
    //console.log(object);

    setTimeout(() => {
      object.loginFailure();
    },1000);

    return false;
  };

  const forgotEmail = (object) => {
    //console.log(object);

    setTimeout(() => {
      object.forgotSuccess();
    },500);

    return false;
  };

  // CONTEXT OBJECT
  const contextObject = {
    loading:loading,
    ready: ready,
    setLoading: setLoading,
    setReady: setReady,
    view:view,
    navigateTo:navigateTo,
    menu:menu,
    toggleMenu: toggleMenu,
    goHome:goHome,
    menuItems:menuItems,
    finalizeMenuClose:finalizeMenuClose,
    menuClose:menuClose,
    submitConnect:submitConnect,
    loginUser:loginUser,
    forgotEmail:forgotEmail,
    projectList:projectList,
    submitJoin:submitJoin,
    setError:setError,
    introList:introList,
  }

  // APP Render
  return (
      <div className="App" id="App" disabled>  
        <Context.Provider value={contextObject}>

        { width <= 850 &&
          <MobileApp />
        }

        {
          width > 850 &&
          <DesktopApp />
        }

      </Context.Provider>
      </div>
  );
}

export default App;
