import './MobileAbout.css';

import MobileValues from './MobileValues';

import { useEffect, useRef, useState } from 'react';

import aboutIntro from '../Resources/Images/About/AboutIntro.jpg';
import narrative1 from '../Resources/Images/About/Narrative1.jpg';
import narrative2 from '../Resources/Images/About/Narrative2.jpg';
import narrative3 from '../Resources/Images/About/Narrative3.jpg';
import narrative4 from '../Resources/Images/About/Narrative4.jpg';
import narrative5 from '../Resources/Images/About/Narrative5.JPG';
import narrative6 from '../Resources/Images/About/Narrative6.jpg';

import location from '../Resources/Images/About/Location2.png'

import Footer from '../Footer/MobileFooter.js'

const introArray = [
    {
        title: "Artists",
        font: '"Sofia", sans-serif',
    },
    {
        title: "Craftsmen",
        font: '"Great Vibes", cursive',
    },
    {
        title: "Particular",
        font: '"Tangerine", cursive',
    },
    {
        title: "Techy",
        font: '"Workbench", sans-serif',
    },
    {
        title: "Creative",
        font: '"Fredericka the Great", serif',
    },
    {
        title: "Passionate",
        font: '"Mrs Saint Delafield", cursive',
    },
    {
        title: "Innovative",
        font: '"Gayathri", sans-serif',
    },
]

const introChangeSpeed = 2000;


const IntroTextChange = () => {
    const [activeWord, setActiveWord] = useState('');
    const [activeFont, setActiveFont] = useState('')
    const wordSelection = useRef(0);
    const initialized = useRef(false);

    const introStyle = {
        '--fontStyle': activeFont,
    }

    const loopWords = () => {
        let newWordSelection = wordSelection.current + 1

        if(newWordSelection > introArray.length-1){
            newWordSelection = 0;
        }
        wordSelection.current = newWordSelection;
        setActiveWord(introArray[newWordSelection].title);
        setActiveFont(introArray[newWordSelection].font);

        setTimeout(() => {
            loopWords();
        },introChangeSpeed)
    };

    useEffect(() => {
        if(!initialized.current){
            initialized.current = true;
            loopWords();
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Sofia"></link>
        <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Great+Vibes&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Great+Vibes&family=Workbench&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Great+Vibes&family=Tangerine:wght@400;700&family=Workbench&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Fredericka+the+Great&family=Great+Vibes&family=Tangerine:wght@400;700&family=Workbench&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Fredericka+the+Great&family=Great+Vibes&family=Mrs+Saint+Delafield&family=Tangerine:wght@400;700&family=Workbench&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Fredericka+the+Great&family=Gayathri:wght@100;400;700&family=Great+Vibes&family=Mrs+Saint+Delafield&family=Tangerine:wght@400;700&family=Workbench&display=swap" rel="stylesheet"></link>
        <div className='AboutIntroText' style={introStyle}>
            {activeWord}
        </div>
        </>

    )
};

const AboutIntroduction = () => {   
    return (
        <div className='AboutIntro'>
            <div className='AboutIntroImageFrame'>
                <img src={aboutIntro} alt={'About Intro'} className='AboutIntroImage'/>
            </div>
            <div className='AboutIntroContent'>
                <div className='AboutIntroTitle'>
                    We Are
                </div>

                < IntroTextChange />
            </div>
        </div>
    )
};

const NarrativeBundle = ({text, image}) => {
    return(
        <div className='NarrativeBundle'>
            <div className='StartNarrativeText'>
                {text}
            </div>

            <img src={image} className='StartNarrativeImage' alt='Start Narrative'/>
        </div>
    )
};

const StartNarrative = () => {
    return (
        <div className='AboutPageSection'>
            <div className='AboutPageTitle'>
                Our Story
            </div>
            <NarrativeBundle image={narrative1} text={'We began as a custom fabrication shop, where we produced unique cabinetry for homes throughout the United States.'}/>
            <NarrativeBundle image={narrative2} text={'After a decade of refinning our processes and skills, we expanded our design capabilities; bringing museums to life across the globe.'}/>
            <NarrativeBundle image={narrative3} text={'Brands began requesting the same level of quality, and we were there to help their product stand out in tradeshows, showrooms, and offices.'}/>
            <NarrativeBundle image={narrative4} text={'Throughout the years, residential construction, and the design freedom it allows for, has kept our interest.'}/>
            <NarrativeBundle image={narrative5} text={'Expanding to neighborhoods and subdivisions, we began shaping a way of living instead of just a place to live.'}/>
            <NarrativeBundle image={narrative6} text={'We are far from over, and feel our story is just beginning.'}/>
            
        </div>
    )
};


const LocationSection = () => {
    return (
        <>
        <div className='LocationSection'>
            <div className='AboutPageTitle'>
                Our Location
            </div>

            <div className='AboutPageText'>
                Raleigh-Durham, North Carolina
            </div>
            <div className='AboutPageText'>
                United States of America
            </div>

        </div>

        <div className='locationMapSection'>
            <img src={location} alt='location' className='locationMap'/>
        </div>
        </>
    )
};


const MobileAbout = () => {
    return(
        <div className='MobileAbout'>
            <AboutIntroduction />
            <StartNarrative />
            <MobileValues />
            <LocationSection />
            <Footer about={true}/>
        </div>
    )
};

export default MobileAbout;