import './MobileLogin.css';

import {useEffect, useState, useContext} from "react";
import {Context} from '../Context';

const loginErrorMessageText = 'Username or Password is incorrect. Please check your entry and try again.';
const forgotErrorMessageText = 'Please enter a valid email address.';
const successMessageText = 'If we have an account with that email address, a reset link will be sent.';
const errorMessageTimer = 4000;
const successMessageTimer = 3500;

const MobileForgotWindow = ({toggleForgot, updateUsername, username, submitForgot, errorMessage, errorDisplay, successDisplay, successMessage}) => {
    return (
        <>
            <div className='MobileLoginHeader'>
                <div className='MobileLoginTitle'>
                    Reset Password
                </div>
                <div className='MobileLoginSubtitle'>
                    Enter your email address to recieve a reset link.
                </div>
            </div>
            <div className='MobileLoginInputs'>
                <input className='MobileLoginInput' onChange={(event) => updateUsername(event.target.value)} value={username} placeholder={'Email Address'}/>


                <button className='MobileLoginButton' onClick={() => submitForgot()}>
                    Submit
                </button>
                <div className='MobileLoginForgot' onClick={() => toggleForgot()}>
                    Back to login.
                </div>
            </div>
            <div className='MobileLoginFooter'>
                {   errorDisplay &&
                    <div className='MobileLoginError'>
                        {errorMessage}
                    </div>
                }
                {   successDisplay &&
                    <div className='MobileLoginSuccess'>
                        {successMessage}
                    </div>  

                }
            </div>
        </>
    )
};

const MobileLoginWindow = ({toggleForgot, updateUsername, username, updatePassword, password, submitLogin, errorMessage, errorDisplay}) => {
    return (
        <>
            <div className='MobileLoginHeader'>
                <div className='MobileLoginTitle'>
                    Welcome Back!
                </div>
                <div className='MobileLoginSubtitle'>
                    Login to continue
                </div>
            </div>
            <div className='MobileLoginInputs'>
                <input className='MobileLoginInput' onChange={(event) => updateUsername(event.target.value)} value={username} placeholder={'Email Address'}/>

                <input className='MobileLoginInput' type="password" onChange={(event) => updatePassword(event.target.value)} value={password} placeholder={'Password'}/>

                <div className='MobileLoginForgot' onClick={() => toggleForgot()}>
                    Forgot Password?
                </div>
                <button className='MobileLoginButton' onClick={() => submitLogin()}>
                    Login
                </button>
            </div>
            <div className='MobileLoginFooter'>
                {   errorDisplay &&
                    <div className='MobileLoginError'>
                        {errorMessage}
                    </div>
                }
            </div>
        </>
    )
}

const MobileLogin = () => {
    const contextObject = useContext(Context);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorDisplay, setErrorDisplay] = useState(false);
    const [errorMessage, setErrorMessage] = useState(loginErrorMessageText);

    const [successDisplay, setSuccessDisplay] =useState(false);
    const [successMessage, setSuccessMessage] = useState(successMessageText);

    const [forgotPassword, setForgotPassword] = useState(false);

    useEffect(() => {
        setPassword('');
    },[forgotPassword])


    const toggleForgot = () => {
        setForgotPassword(!forgotPassword);
    };

    const updateUsername = (username) => {
        setUsername(username);
    };

    const updatePassword = (password) => {
        setPassword(password);
        
    };

    const checkLoginInput = () => {
        let valid = true;
        if(username === '') valid = false;
        if(password === '') valid = false;

        return valid;
    };

    const checkForgotInput = () => {
        let valid = true;
        if(username === '') valid = false;

        return valid;
    };

    const resetFields = () => {
        setPassword('');
    };

    const loginError = () => {
        resetFields();

        setErrorMessage(loginErrorMessageText);
        setErrorDisplay(true);

        setTimeout(()=> {
            setErrorDisplay(false);
        },errorMessageTimer)
    };

    const forgotError = () => {
        resetFields();

        setErrorMessage(forgotErrorMessageText);
        setErrorDisplay(true);

        setTimeout(()=> {
            setErrorDisplay(false);
        },errorMessageTimer)
    };

    const forgotSuccess = () => {
        setSuccessMessage(successMessageText);
        setSuccessDisplay(true);

        setTimeout(() => {
            setSuccessDisplay(false);
            setForgotPassword(false);
        },successMessageTimer)
    };

    const loginSuccess = () => {
        console.log('Successful Login')
    };

    const loginFailure = () => {
        loginError();
    };

    const submitLogin = () => {
        if(checkLoginInput()){
            const loginObject = {
                username: username,
                password: password,
                loginSuccess:loginSuccess,
                loginFailure:loginFailure,
            }
            resetFields();

            contextObject.loginUser(loginObject);
        }
        else{
            loginError();
        }

    };

    const forgotFailure = () => {
        forgotError();
    };

    const submitForgot = () => {
        if(checkForgotInput()){
            const forgotemailObject = {
                username: username,
                forgotSuccess:forgotSuccess,
                forgotFailure:forgotFailure,
            }
            contextObject.forgotEmail(forgotemailObject);

        }
        else{
            forgotError();
        }
    };

    return(

        <div className='MobileLoginPage'>
            <div className='MobileLoginContent'>
                {   !forgotPassword &&
                    <MobileLoginWindow toggleForgot={toggleForgot} updateUsername={updateUsername} username={username} updatePassword={updatePassword} password={password} submitLogin={submitLogin} errorMessage={errorMessage} errorDisplay={errorDisplay} setUsername={setUsername}/>
                }

                {   forgotPassword &&
                    <MobileForgotWindow toggleForgot={toggleForgot} updateUsername={updateUsername} username={username} submitForgot={submitForgot} errorMessage={errorMessage} errorDisplay={errorDisplay} setUsername={setUsername} successDisplay={successDisplay} successMessage={successMessage}/>
                }
                
            </div>
        </div>
    )
};

export default MobileLogin;