import './MobileIntroServices.css';

// Services Images
import Planning from '../Resources/Images/Services/Planning.jpg';
import Design from '../Resources/Images/Services/Design.jpg';
import Engineering from '../Resources/Images/Services/Engineering.jpg';
import Construction from '../Resources/Images/Services/Construction.jpg';
import { SiSitepoint } from "react-icons/si";

// Services Section Controls
const servicesTitle = 'Our Services';
const projectSubtitle = 'We reimagine functional spaces and structures for brands and homeowners who demand the best.';
const introServices = [
    {
        'title': 'Consultation',
        'text': "Project success starts at the very first step. We begin by deep-diving into how a space can work for you.",
        'list': ['Brand Experience', 'Workforce Enablement', 'Service Innovation', 'Expense Estimating', 'Forecasting'],
        'image': Planning,
    }, 
    {
        'title': 'Design',
        'text': 'We put design quality at the heart of every project; crafting an experience worth being immersed in.',
        'list': ['Branded Environments','Experimental Design', 'High-res Renderings', 'Tailored Experiences', 'Immersive Detail'],
        'image': Design,
    }, 
    {
        'title': 'Engineering',
        'text': 'Precision and emphasis on detail sets us apart. We continually update our technology and equipment to surpass quality standards.',
        'list': ['Architectural Packages', 'Design Engineering', '3-Dimensional Scans', 'Laser & Waterjet', 'Multi-Axis CNC' ],
        'image': Engineering,
    }, 
    {
        'title': 'Construction',
        'text': 'We hold ourselves to a higher standard, and it shows. Prioritizing quality and speed while ensuring site safety.',
        'list': ['New Construction','Remodel Construction', 'Project Management', 'Brand Displays', 'Office Fit Out' ],
        'image': Construction,
    }, 
]


// Home - Mobile
const MobileIntroServices = () => {
    return (
        <div className='ServicesSection'>
            <div className='ServicesInnerWindow'>
                <div className='ServicesTitle'>
                    {servicesTitle}
                </div>
                <div className='ServicesIntro'>
                    {projectSubtitle}
                </div>
                {
                    introServices.map((object, i) => (
                        <div className='Services' key={i}>
                            <img src={object.image} alt={'Service'} className='ServicesImage'/>
                            <div className='ServicesSubTitle'>
                                {object.title}
                            </div>
                            <div className='ServicesText'>
                                {object.text}
                            </div>
                            {   object.list.map((text, i) => (
                                <div className='ServicesListItem' key={i}>
                                    < SiSitepoint className='ServicesListSymbol'/>
                                    <div className='ServicesListText'>
                                        {text}
                                    </div>
                                    
                                </div>
                            ))
                            }
                        </div>
                    ))
                }


            </div>
        </div>
    )
};

export default MobileIntroServices;