import './MobileApp.css';
import {Context} from '../Context';
import { useContext, useState, useEffect } from 'react';

// Primary Pages of Mobile App
import MobileHome from '../Home/MobileHome';
import MobileResidential from '../Residential/MobileResidential';
import MobileCommercial from '../Commercial/MobileCommercial';
import MobileAbout from '../About/MobileAbout';
import MobileLogin from '../Login/MobileLogin';
import MobileConnect from '../Connect/MobileConnect';

// Loading Page
import Loading from '../Loading/Loading';

// Icons
import { FaBars } from "react-icons/fa6";
import { FaRegWindowClose } from "react-icons/fa";

// Images
import KysonIcon from "../Resources/Images/Logo/KysonIconInverse.png";
import KysonDesignsText from "../Resources/Images/Logo/KysonDesignsTextInverse.png";

// Navigation Bar
const MobileNav = ({navbarTop}) => {
    const contextObject = useContext(Context);
    const navigationBarCss = {
        '--NavbarTop': navbarTop+'px',
    };

    return(
        <div className='MobileNavbar' style={navigationBarCss}>
            <div className='NavName'>
                <img src={KysonDesignsText} alt={"Kyson Name Text"} className={"KysonName"}/>
            </div>
            <div className='NavLogo' onClick={() => contextObject.goHome()}>
                <img src={KysonIcon} alt={"Kyson Icon"} className={"KysonIcon"}/>
            </div>
            <div className='NavMenu' onClick={() => contextObject.toggleMenu()}>
                <FaBars className='MenuIcon'/>
            </div>
        </div>
    )
}

// Menu Bar
const Menu = () => {
    const contextObject = useContext(Context);

    // Menu Margin for Transition
    const [menuMargin, setMenuMargin] = useState(100);
    const menuCSS = {
        "--marginLeft": menuMargin+"%"
    }

    // Transition Menu to Open Position
    const openMenu = (margin) => {
        if(margin > 30){
            const newMargin = margin - 5;
            setMenuMargin(newMargin);

            setTimeout(() => {
                openMenu(newMargin);
              }, 20)
        }
    };

    // Transition Menu to Closed Position
    const closeMenu = (margin) => {
        if(margin > 100) contextObject.finalizeMenuClose();

        else{
            const newMargin = margin + 5;
            setMenuMargin(newMargin);

            setTimeout(() => {
                closeMenu(newMargin);
              }, 20)
        }
    };

    // INITIAL LOADING PERIOD
    useEffect(() => {
        if(contextObject.menuClose) closeMenu(menuMargin);
        else openMenu(menuMargin);
    }, [contextObject.menuClose]); // eslint-disable-line react-hooks/exhaustive-deps

    // Handle Navigation Link Selection
    const selectNavigationLink = (label) => {
        contextObject.navigateTo(contextObject.menuItems[label])
    };

    return(
        <div className='MenuWindow'>
            <div className='Menu' style={menuCSS}>
                {   
                    Object.keys(contextObject.menuItems).map((label, i) => (
                        <div key={i} className='MenuLink' onClick={() => selectNavigationLink(label)}>{label}</div>
                    ))
                }
                <div className='MenuLink Last-Option' onClick={() => contextObject.toggleMenu()}>Exit</div>
            </div>
        </div>
    )
}

// Primary Mobile Window
const MobilePrimary = ({handleScroll}) => {
    const contextObject = useContext(Context);

    return(
        <div className='MobilePrimary' onScroll={(e) => handleScroll(e)}>
            {
                contextObject.menu &&
                <Menu />
            }

            {   contextObject.view === "Home" &&
                <MobileHome />
            }

            {   contextObject.view === "Residential" &&
                <MobileResidential />
            }

            {   contextObject.view === "Commercial" &&
                <MobileCommercial />
            }

            {   contextObject.view === "About" &&
                <MobileAbout />
            }

            {   contextObject.view === "Login" &&
                <MobileLogin />
            }

            {   contextObject.view === "Connect" &&
                <MobileConnect />
            }

        </div>
    )
}

// Complete Mobile Application Window
const MobileApp = () => {
    const contextObject = useContext(Context);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [ navbarTop, setNavbarTop] = useState(0);
    const [ transitioning, setTransitioning ] = useState(false);

    const hideNavBar = (top) => {
        let newTopPosition = top - 1;
        if(newTopPosition >= -60) {
            setNavbarTop(newTopPosition);
            setTimeout(() => {
                hideNavBar(newTopPosition);
            },5);
        }
        else setTransitioning(false);
    };

    const showNavBar = (top) => {
        let newTopPosition = top + 1;
        if(newTopPosition <= 0) {
            setNavbarTop(newTopPosition);
            setTimeout(() => {
                showNavBar(newTopPosition);
            },5);
        }
        else setTransitioning(false);
    };
 
    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const position = Math.ceil(
            (scrollTop / (scrollHeight - clientHeight)) * 100
        );
        setScrollPosition(position);

        if(!transitioning) {
            if(position>scrollPosition) {
                setTransitioning(true)
                hideNavBar(navbarTop);
            }
            else if(position < scrollPosition){
                setTransitioning(true);
                showNavBar(navbarTop)
            }
        };
    };

    return(
        <div className="MobileWindow" disabled>
            {   contextObject.loading &&
                <Loading />
            }

            {   !contextObject.loading &&
                <>
                    <MobileNav navbarTop={navbarTop}/>
                    <MobilePrimary handleScroll={handleScroll}/>
                </>

            }
        </div>
    )
}

export default MobileApp;