import './MobileHome.css';

// Introduction
import MobileIntro from '../Introduction/MobileIntro';

// Engagement
import MobileEngagment from '../Engagement/MobileEngagement';

// Projects
import MobileProjects from '../Projects/MobileProjects';

// Services
import MobileIntroServices from '../Services/MobileIntroServices';

// About
import MobileValues from '../About/MobileValues';

// Footer
import MobileFooter from '../Footer/MobileFooter';



// Mobile
const MobileHome = () => {
    return (
        <div className='MobileHome'>
            <MobileIntro />
            <MobileEngagment />
            <MobileProjects />
            <MobileIntroServices />
            <MobileValues home={true}/>
            <MobileFooter />
        </div>
    )
};

export default MobileHome;