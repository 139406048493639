import './DesktopApp.css';
import {Context} from '../Context';
import { useContext, useState, useEffect } from 'react';
import Logo from '../Resources/Images/Logo/KysonIconInverse.png'
import Name from '../Resources/Images/Logo/KysonDesignsTextInverse.png'
import DesktopHome from '../Home/DesktopHome';
import DesktopResidential from '../Residential/DesktopResidential';
import DesktopCommercial from '../Commercial/DesktopCommercial';
import DesktopAbout from '../About/DesktopAbout';
import DesktopLogin from '../Login/DesktopLogin';
import DesktopConnect from '../Connect/DesktopConnect';

// Loading Page
import Loading from '../Loading/Loading';

const NavigateBar = ({navbarTop,menuItems,view,navigateTo}) => {
    const navigationBarCss = {
        '--NavbarTop': navbarTop+'px',
    };

    return(
        <div className='DNavigationBar' style={navigationBarCss}>
            <div className='DNavigationBar-Logo-Container'>
                <img src={Logo} alt='Kyson Logo'className='DNavigationBar-Logo'/>
                <img src={Name} alt='Kyson Name' className='DNavigationBar-Name'/>
            </div>
            <div className='DNavigationBar-Menu'>
                {   menuItems &&
                    Object.keys(menuItems).map((label, key) => {
                        if(view !== label) return(
                            <div key={key} className='DNavigationBar-Menu-Item' onClick={() => navigateTo(label)}>{label}</div>
                        )
                    })
                }
            </div>
        </div>
    )
};

const DesktopDisplay = ({view,handleScroll}) => {
    return(
        <div className='Desktop-Display' onScroll={(event) => handleScroll(event)}>
            {   view === "Home" &&
                <DesktopHome />
            }
            {   view === "Residential" &&
                <DesktopResidential />
            }
            {   view === "Commercial" &&
                <DesktopCommercial />
            }
            {   view === "About" &&
                <DesktopAbout />
            }
            {   view === "Login" &&
                <DesktopLogin />
            }
            {   view === "Connect" &&
                <DesktopConnect />
            }
        </div>
    )
};

const DesktopApp = () => {
    const contextObject = useContext(Context);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [navbarTop, setNavbarTop] = useState(0);
    const [ transitioning, setTransitioning ] = useState(false);

    const hideNavBar = (top) => {
        let newTopPosition = top - 1;
        if(newTopPosition >= -60) {
            setNavbarTop(newTopPosition);
            setTimeout(() => {
                hideNavBar(newTopPosition);
            },5);
        }
        else setTransitioning(false);
    };

    const showNavBar = (top) => {
        let newTopPosition = top + 1;
        if(newTopPosition <= 0) {
            setNavbarTop(newTopPosition);
            setTimeout(() => {
                showNavBar(newTopPosition);
            },5);
        }
        else setTransitioning(false);
    };
 
    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const position = Math.ceil(
            (scrollTop / (scrollHeight - clientHeight)) * 100
        );
        setScrollPosition(position);

        if(!transitioning) {
            if(position>scrollPosition) {
                setTransitioning(true)
                hideNavBar(navbarTop);
            }
            else if(position < scrollPosition){
                setTransitioning(true);
                showNavBar(navbarTop)
            }
        };
    };

    return(
        <div className="DesktopWindow" disabled>
            {   contextObject.loading &&
                <Loading desktop={true}/>
            }

            <NavigateBar navbarTop={navbarTop} menuItems={contextObject.menuItems} view={contextObject.view} navigateTo={contextObject.navigateTo}/>
            <DesktopDisplay view={contextObject.view} handleScroll={handleScroll}/>

        </div>
    )
};

export default DesktopApp;