import './MobileIntro.css';

import { useState, useEffect, useRef } from 'react';

// Introduction Section Images
import intro1 from '../Resources/Images/Intro/intro1.jpeg';
import intro2 from '../Resources/Images/Intro/intro2.jpg';
import intro3 from '../Resources/Images/Intro/intro3.jpg';
import intro4 from '../Resources/Images/Intro/intro4.png';
import intro5 from '../Resources/Images/Intro/intro5.jpg';

// Introduction Section Controls
const introCycleSpeed = 3000;
const introMoveSpeed = 10;
const introMoveDistance = .02;
const introStartingMargin = -2;
const imageArray = [intro1, intro2, intro4, intro3, intro5];
const headerArray = ['Commercial','Residential','Design','Execution','Product'];
const subtextArray = ['Empowering Brands to Stand Out', 'Bringing Museum Quality Home', 'Planning an Experience', 'Creating Conversations', 'Developing Customer Experience'];


// Home - Mobile
const MobileIntro = () => {
    // States for Image & Text Changes
    const [introCycleCount, changeIntroCount] = useState(0);
    const [imageShown, changeImageShown] = useState(intro1);
    const [headerShown, changeHeaderShown] = useState(headerArray[0]);
    const [subtextShown, changeSubtextShown] = useState(subtextArray[0]);
    const [introImageMargin, setIntroImageMargin] = useState(introStartingMargin);
    const introMoveReset = useRef(0);
    const introInitialize = useRef(0);


    //Shift Intro Image Loop
    const moveIntroImage = (previousPosition) => {
        setTimeout(() => {
            if(introMoveReset.current === 1){
                introMoveReset.current = 0;
                moveIntroImage(introStartingMargin);
            }
            else{
                let newPosition = previousPosition - introMoveDistance;
                setIntroImageMargin(newPosition);
                moveIntroImage(newPosition);
            }
        }, introMoveSpeed)
    };
    
    // Continual Loop of Cycle Counter - Speed Declared Above
    const cycleIntro = (currentCount) => {
        setTimeout(() => {
            let newCount = currentCount + 1;
            if(newCount === imageArray.length) newCount = 0;
            changeIntroCount(newCount);
            introMoveReset.current = 1;
            setIntroImageMargin(introStartingMargin);

            cycleIntro(newCount);
        }, introCycleSpeed)
    };

    // Update Image & Text on Cycle Counter
    useEffect(() => {
        changeImageShown(imageArray[introCycleCount]);
        changeHeaderShown(headerArray[introCycleCount]);
        changeSubtextShown(subtextArray[introCycleCount]);
    },[introCycleCount]);

    // Initial Kick-off of Cycle Counter
    useEffect(() => {
        if(introInitialize.current === 0){
            introInitialize.current = 1;
            cycleIntro(introCycleCount);
            moveIntroImage(introImageMargin);
        }
    }, );

    const introImageCSS = {
        '--MarginLeft' : introImageMargin+'%',
    };

    // Rendering of Introduction Section
    return(
        <div className='IntroWindow'>
            <img src={imageShown} className='IntroImage' alt='intro' style={introImageCSS}/>
            <div className='IntroText'>
                <p className='IntroHeader'>{headerShown}</p>
                <p className='IntroSub'>{subtextShown}</p>
            </div>
        </div>
    )
};

export default MobileIntro;