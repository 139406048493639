import './MobileServices.css';


const MobileService = ({title,textArray,image}) => {
    console.log(textArray)
    return(
        <div className='MobileService'>

            <div className='ServicesTitleSecondary'>
                {title}
            </div>

            <img className='ServicesImageSecondary' src={image} alt='Service'/>

            <div className='ServicesTextSectionSecondary'>
                {textArray.map((text,key) => 
                    {   
                        if(key === 0) return (<div className='ServicesTextReverse' key={key}>{text}</div>)
                        else if(key === 1) return (<div className='ServicesTextCentered' key={key}>{text}</div>)
                        else if(key === 2) return (<div className='ServicesTextNormal' key={key}>{text}</div>)
                        else if(key === 3) return (<div className='ServicesTextCentered' key={key}>{text}</div>)
                        else if(key === 4) return (<div className='ServicesTextReverse' key={key}>{text}</div>)
                        else if(key === 5) return (<div className='ServicesTextCentered' key={key}>{text}</div>)
                        else if(key === 6) return (<div className='ServicesTextNormal' key={key}>{text}</div>)
                        else if(key === 7) return (<div className='ServicesTextCentered' key={key}>{text}</div>)
                        else return (<div className='ServicesTextReverse' key={key}>{text}</div>)
                    }
                )}
            </div>


            
        </div>
    )
};


const MobileServices = ({services}) => {

    return(
        <div className='MobileServices'>
            <div className='MobileServicesTitle'>
                Services
            </div>

            {
                services.map((serviceObject, key) => (
                    <MobileService key={key} title={serviceObject.title} textArray={serviceObject.textArray} image={serviceObject.image}/>
                ))
            }
            
        </div>
    )
};

export default MobileServices;