import axios from "axios";

const BACKEND_IP = 'https://kysondesigns.com/'
const API_PATH = 'api/'
const API_URL = BACKEND_IP + API_PATH;

export const getProjectList = async (setError, setProjectList) => {
  axios.get(`${API_URL}getProjectList`)
  .then(function(res) {
    setProjectList(res.data);
    return(res)
  })
  .catch(function(error) {
    setError(error);
  })
};

export const getIntroList = async (setError, setIntroList) => {
  axios.get(`${API_URL}getIntroList`)
  .then(function(res) {
    setIntroList(res.data);
    return(res)
  })
  .catch(function(error) {
    setError(error);
  })
}

export const submitJoin = async (emailAddress) => {
  console.log(emailAddress);
};

export const submitConnect = async (connectObject) => {
  console.log(connectObject);
};