import './MobileFooter.css';
import { useContext, useState } from 'react';
import {Context} from '../Context';

// Footer Images
import Logo from '../Resources/Images/Logo/KysonIconInverse.png';
import KysonText from '../Resources/Images/Logo/KysonDesignsTextInverse.png';
import { FaRegCopyright } from "react-icons/fa";


// Home - Mobile
const MobileFooter = ({connect=false, about=false, residential=false, commercial=false}) => {
    const contextObject = useContext(Context);

    const [emailAddress, setEmailAddress] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const checkEmail = () => {
        let failed = false
        try{
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
            if(re.test(emailAddress)){
                // Valid Email
            }
            else{
                failed = true;
            }
        }
        catch{
            failed = true;
        }

        return !failed;
    };

    const submitEmail = () => {
        if(checkEmail()) {
            contextObject.submitJoin(emailAddress);
            setSuccess(true);
            setEmailAddress('');
            setTimeout(() => {
                setSuccess(false);
            },1000)
        }
        else{
            setError(true);
            setTimeout(() => {
                setError(false);
            },1000)
        }
    };

    const handleChange = (event) => {
        setEmailAddress(event.target.value);
    };

    const handleLinkClick = (link) => {
        contextObject.navigateTo(link);
    };

    return(
        <div className='FooterSection'>
            <div className='FooterKyson' onClick={() => window.location.href='/'}>
                <img src={Logo} alt={'Logo'} className={'FooterLogo'} />
                <img src={KysonText} alt={'Kyson Designs'} className={'FooterLogoText'} />
            </div>

            {   !connect &&
                <>
                    <div className='FooterConnectTitle'>
                        Let's Connect
                    </div>
                    <div className='FooterConnectText'>
                        Start the conversation and we will take it from there.
                    </div>
                    <div className='FooterConnectLink' onClick={() => handleLinkClick('Connect')}>
                        Connect Now
                    </div>
                </>
            }


            <div className='FooterSubscribeTitle'>
                Stay Updated
            </div>
            <div className='FooterSubscribeText'>
                Subscribe for our latest news and projects
            </div>
            <div className='FooterEmail'>
                <input placeholder='Email address' type='email' value={emailAddress} className='FooterEmailInput' onChange={event => handleChange(event)}/>
                <button type='button' className='FooterEmailButton' onClick={() => submitEmail()}>
                    Submit
                </button>
            </div>
            {   error &&
                <div>
                    Please Enter a Valid Email
                </div>
            }
            {   success &&
                <div>
                    Successfully Sent Email Address
                </div>
            }

            <div className='FooterLinks'>
                {   !commercial &&
                <div className='FooterLink' onClick={() => handleLinkClick('Commercial')}>
                    Commercial
                </div>
                }
                {   commercial &&
                <div className='FooterLink' onClick={() => handleLinkClick('Home')}>
                    Home
                </div>
                }




                {   !residential &&
                <div className='FooterLink' onClick={() => handleLinkClick('Residential')}>
                    Residential
                </div>
                }
                {   residential &&
                <div className='FooterLink' onClick={() => handleLinkClick('Home')}>
                    Home
                </div>
                }

                {   !about   &&
                    <div className='FooterLink' onClick={() => handleLinkClick('About')}>
                        About Us
                    </div>
                }
                {   about   &&
                    <div className='FooterLink' onClick={() => handleLinkClick('Home')}>
                        Home
                    </div>
                }

                {   !connect    &&
                <div className='FooterLink' onClick={() => handleLinkClick('Connect')}>
                    Connect
                </div>
                }

                {   connect    &&
                <div className='FooterLink' onClick={() => handleLinkClick('Home')}>
                    Home
                </div>
                }


            </div>

            <div className='FooterCopy'>
                <div className='FooterCopyIcon'>
                    <FaRegCopyright />
                </div>
                <div className='FooterCopyText'>
                     Kyson Designs Inc. 2024
                </div>
            </div>

        </div>
    )
};

export default MobileFooter;