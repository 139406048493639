import './MobileValues.css'

import { useContext } from 'react';
import {Context} from '../Context';

// About Us Images 
import Kaizen from '../Resources/Images/About/Kaizen.png';

// About Section Controls
const aboutTitle = 'Our Values';
const aboutTextStart = 'The name Kyson originates from the Japanese word “Kaizen”, translating to "change for the better". With the world evolving so quickly, this guiding principle ensures we continue to exceed our customer’s expectations.'
const aboutTextContinued = 'We focus on experience and ensure yours will be nothing less than our best.';
const aboutLink = 'Learn More';

const aboutTextEnd = 'From custom cabinetry to musuem exhibitry, we have been there. Every project we take on is expected to be better than the last. Learning from the lessons of our past, we are pursuing perfection.';


// Home - Mobile
const MobileValues = ({home = false}) => {
    const contextObject = useContext(Context);

    const navigateToAbout = () => {
        contextObject.navigateTo('About')
    };

    return (
        <div className='AboutSection'>
            <div className='AboutTitle'>
                {aboutTitle}
            </div>
            <div className='AboutText'>
                {aboutTextStart}
            </div>
            <img src={Kaizen} alt={'Kaizen'} className={'AboutKaizen'} />

            {   home &&
            <div className='AboutLink' onClick={() => navigateToAbout()}>
                {aboutLink}
            </div>
            }

            {
                !home &&
                <>
                <div className='AboutText'>
                        {aboutTextContinued}
                </div>
                <div className='AboutText'>
                        {aboutTextEnd}
                </div>
                </>
            }

        </div>
    )
};

export default MobileValues;