import './MobileResidential.css';

import residential from '../Resources/Images/Residential/Residential.jpg';
import construction from '../Resources/Images/Residential/Construction.jpg';
import land from '../Resources/Images/Residential/Land.jpg';
import Cabinets from '../Resources/Images/Residential/Cabinets.jpg';

import MobileServices from '../Services/MobileServices';

import MobileProjects from '../Projects/MobileProjects';

import MobileFooter from '../Footer/MobileFooter';

const residentialServices = [
    {
        title:'Construction',
        textArray: ["Architectural Drawings","Design & Engineering","General Contracting"],
        image: construction,
    },
    {
        title:'Land Development',
        textArray: ["Design Engineering", "Permitting & Planning", "Project Management",  "Civil Engineering", "Grading & Stormwater", "Utilities Infrastructure", "Monuments & Signage"],
        image: land,
    },
    {
        title:'Finish Work',
        textArray: ["Kitchen & Bath Cabinets", "Furniture Pieces", "Trim & Millwork"],
        image: Cabinets,
    },
]

const MobileResidentialIntroduction = () => {
    return(
        <div className='MobileResidentialIntroduction'>
            <div className='AboutIntroImageFrame'>
                <img src={residential} alt={'About Intro'} className='AboutIntroImage'/>
            </div>
            <div className='AboutIntroContent'>
                <div className='AboutIntroTitle'>
                    Residential
                </div>
            </div>
        </div>
    )
};

const MobileResidential = () => {
    return(
        <div className='MobileResidential'>
            <MobileResidentialIntroduction />
            <MobileServices services={residentialServices}/>
            <MobileProjects filter={'Residential'}/>
            <MobileFooter residential={true}/>
        </div>
    )
};

export default MobileResidential;