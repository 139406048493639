import './MobileProjects.css';

import { useState, useEffect, useRef, useContext } from 'react';
import {Context} from '../Context';

// Projects Section Images & Icons
import { FaAngleLeft,FaAngleRight } from "react-icons/fa6";

const serverPath = 'http://kysondesigns.com/'


// Projects Section Controls
const projectTitle = 'Our Projects';
const projectText = 'We work with brands across many industries to provide a one-of-a-kind experience and clients who demand the best from their homes.';
const projectChangeInterval = 10;
const projectTick = 200;


// Project Section - Home - Mobile
const ProjectScroll = ({projectTray = false, projectList}) => {
    const projectCounter = useRef(0);
    const projectIterator = useRef(0);
    const projectInitialized = useRef(false);
    const [projectTitle, setProjectTitle] = useState('');
    const imageSelection = useRef(0);
    const [primaryImage, setPrimaryImage] = useState();
    const [thumbnailArray, setThumbnailArray] = useState([]);

    // Sets Thumbnail Array
    const setThumbnails = () => {
        let newArray = [];
        projectList[projectCounter.current].images.forEach((image,key) => {
            if(imageSelection.current !== key){
                const newObject = {
                    'key' : key,
                    'image' : image,
                }
                newArray.push(newObject);
            }
        });
        setThumbnailArray(newArray);
    };

    // Refreshes Display
    const setDisplay = () => {
        setThumbnailArray([]);
        setProjectTitle(projectList[projectCounter.current].project);
        setPrimaryImage(serverPath+projectList[projectCounter.current].images[imageSelection.current]);
        setThumbnails();
    };

    // Selects New Primary Image
    const selectNewImage = (selection) => {
        projectIterator.current = 0;
        imageSelection.current = selection;
        setDisplay();
    };

    // Go to Next Project
    const nextProject = () => {
        projectIterator.current = 0;
        let nextCounter = projectCounter.current + 1;
        if(nextCounter > projectList.length - 1) nextCounter = 0;

        projectCounter.current = nextCounter;
        imageSelection.current = 0;

        setDisplay();
    };

    // Go to Previous Project
    const previousProject = () => {
        projectIterator.current = 0;
        let nextCounter = projectCounter.current - 1;
        if(nextCounter < 0) nextCounter = projectList.length - 1;

        projectCounter.current = nextCounter;
        imageSelection.current = 0;
        
        setDisplay();
    };

    const getNextImage = () => {
        const newValue = imageSelection.current + 1;
        selectNewImage(newValue);
    };

    // Check Increment of Project
    const incrementProject = () => {
        setTimeout(() => {
            if(projectIterator.current >= projectChangeInterval) {
                if(imageSelection.current === projectList[projectCounter.current].images.length - 1){
                    setThumbnailArray([]);
                    nextProject();
                }
                else{
                    getNextImage();
                }
                
                projectIterator.current = 0;
            }
            else projectIterator.current = projectIterator.current + 1;
            incrementProject();
        },projectTick);
    };

    // Initialize Projects
    const initializeProjects = () => {
        setDisplay();
        incrementProject();
    };

    // Call to Initialize
    useEffect(() => {
        if(projectInitialized.current === false){
            initializeProjects();
            projectInitialized.current = true;
        }
    },);

    // Renders Project Section
    return (
        <div className='ProjectScroll'>



            <div className='ProjectImageWindow'>
                <div className='ProjectImageMain'>
                    {   primaryImage &&
                        <img src={primaryImage} className='ProjectImage' alt={'Main Project'}/>
                    }
                </div>

                {   projectTray === true &&
                    <div className='ProjectTray'>
                        {   thumbnailArray &&
                            thumbnailArray.map((thumbObject) => (
                                <img key={thumbObject.key} src={thumbObject.image} alt={'Thumbnail'} className={'ProjectThumbnail'} onClick={() => selectNewImage(thumbObject.key)}/>
                            ))
                        }
                    </div>
                }

            </div>

            <div className='ProjectScrollHeader'>
                <FaAngleLeft className='ProjectChange' onClick={() => previousProject()}/>
                <div className='ProjectName'>
                    {
                    projectTitle
                    }
                </div>
                <FaAngleRight className='ProjectChange' onClick={() => nextProject()}/>
            </div>


        </div>
    );
};



// Home - Mobile
const MobileProjects = ({filter='Highlighted'}) => {
    const contextObject = useContext(Context);
    const [filteredProjectList, setFilteredProjectList] = useState([]);
    const [readyToRun, setReadyToRun] = useState(false);

    const filterList = () => {
        if(filter === 'Highlighted'){
            setFilteredProjectList(contextObject.projectList.filter((project) => project.highlight === true));
        }
        else{
            setFilteredProjectList(contextObject.projectList.filter((project) => project.tag === filter));
            
        }
        setReadyToRun(true);
    };

    useEffect(() => {
        filterList();
    }, [])

    return (
        <div className='ProjectSection'>
            {   filter === 'All' &&
                <>
                <p className='ProjectTitle'>
                    {projectTitle}
                </p>
                <p className='ProjectText'>
                    {projectText}
                </p>
                </>
            }

            {   readyToRun &&
                <ProjectScroll filter={filter} projectList={filteredProjectList}/>
            }
        </div>
    )
};

export default MobileProjects;