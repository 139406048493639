import './MobileCommercial.css';

import commercial from '../Resources/Images/Commercial/Commercial.jpeg';

import Branding from '../Resources/Images/Commercial/Branding.jpg';
import Marketing from '../Resources/Images/Commercial/Marketing.jpg';
import Office from '../Resources/Images/Commercial/Office.jpg';
import Display from '../Resources/Images/Commercial/Display.jpg';

import MobileServices from '../Services/MobileServices';

import MobileProjects from '../Projects/MobileProjects';

import MobileFooter from '../Footer/MobileFooter';

const commercialServices = [
    {
        title:'Branding',
        textArray: ["Tailored Design", "Renderings", "One-Off Fabrication"],
        image: Branding,
    },
    {
        title:'Marketing',
        textArray: ["Storefronts","Tradeshows", "Pop-Ups", "Signage"],
        image: Marketing,
    },
    {
        title:'Offices',
        textArray: ["Reception Desks","Wall Cladding", "Custom Cabinetry"],
        image: Office,
    },
    {
        title:'Displays',
        textArray: ["Museum Exhibits","Headquarter Monuments", "Product Displays"],
        image: Display,
    },
]

const MobileCommercialIntroduction = () => {
    return(
        <div className='MobileCommercialIntroduction'>
            <div className='AboutIntroImageFrame'>
                <img src={commercial} alt={'About Intro'} className='AboutIntroImage'/>
            </div>
            <div className='AboutIntroContent'>
                <div className='AboutIntroTitle'>
                    Commercial
                </div>
            </div>
        </div>
    )
};


const MobileCommercial = () => {
    return(
        <div className='MobileResidential'>
            <MobileCommercialIntroduction />
            <MobileServices services={commercialServices}/>
            <MobileProjects filter={'Commercial'}/>
            <MobileFooter commercial={true} />
        </div>
    )
};

export default MobileCommercial;