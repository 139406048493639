import './DesktopResidential.css';

const DesktopResidential = () => {
    return(
        <div className='Desktop-Residential'>

        </div>
    )
};

export default DesktopResidential;