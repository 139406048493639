import './MobileEngagement.css';

import { useContext, useState, useEffect, useRef } from 'react';
import {Context} from '../Context';

// Engagment Section Images
import brand1 from '../Resources/Images/Brands/Automation Anywhere.png';
import brand2 from '../Resources/Images/Brands/Alexandria.jpg';
import brand3 from '../Resources/Images/Brands/Hubbell.png';
import brand4 from '../Resources/Images/Brands/Microsoft.png';
import brand5 from '../Resources/Images/Brands/SK2.png';
import brand6 from '../Resources/Images/Brands/Toyota.png';

// Engagment Section Controls
const brandArray = [brand1,brand2,brand3,brand4,brand5,brand6]
const EngagementButtonText = "Let's Connect"
const EngagementTitle = "Brands who placed their trust in us"
const initialBrandStart = 110;
const createNewBrand = 75;
const brandChange = 20;
const deleteBrand = -30;

// Brand Scroll - Engagment Section - Home - Mobile
const BrandElement = ({position,brand}) => {
    const marginStyling = {
        '--elementPosition' : position+'%'
    }

    return (
        <div className='BrandElement' style={marginStyling}>
            <img src={brand} alt={'Brand'} className='BrandImage'/>
        </div>
    )
};

// Engagment Section - Home - Mobile
const BrandScroll = () => {
    // Array to Store Brand Element Objects for Render
    const [brandElements, setBrandElements] = useState([]);
    // Keeps Count of Brand Elements for ID
    const brandCount = useRef(0);
    const brandSelector = useRef(0);
    // Tracks whether initialized
    const initialized = useRef(false);


    // Increment Brand Element Locations
    const incrementBrands = () => {
        setTimeout(() => {
            let newArray = []
            brandElements.forEach(elementObject => {
                let updatedObject = elementObject;
                updatedObject.position = elementObject.position-.25;

                if(updatedObject.position === createNewBrand) createNewElement();

                if(updatedObject.position > deleteBrand) newArray.push(updatedObject);

                setBrandElements(newArray);
            });
        
            incrementBrands();
        }, brandChange)

    };
    
    // Add New Element to Render Array
    const createNewElement = () => {
        const newValue = brandCount.current + 1;
        brandCount.current = newValue;

        let newBrandSelector = brandSelector.current + 1;
        if(newBrandSelector > brandArray.length - 1){
            newBrandSelector = 0;
        }
        brandSelector.current = newBrandSelector;

        let newObject = {
            'id': newValue,
            'position': initialBrandStart,
            'brand': brandArray[newBrandSelector],
        }

        let newArray = brandElements;
        newArray.push(newObject);
        setBrandElements(newArray);

    };

    // If Not Initialize Create New Element
    useEffect(() => {
        if(!initialized.current) {
            initialized.current = true;
            createNewElement();
            incrementBrands();
        }
    },[]);// eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='EB-Scroll'>
            {   
                brandElements.map((object) => (
                    <BrandElement key={object.id} position={object.position} brand={object.brand}/>
                ))
            }
            </div>
    )
};

// Home - Mobile
const MobileEngagment = () => {
    const contextObject = useContext(Context);

    // Rendering of Engagement Section 
    return(
        <div className='EngagementWindow'>
            <button className='EngageButton' onClick={() => contextObject.navigateTo('Connect')}>{EngagementButtonText}</button>

            <p className='EngageTitle'>
                {EngagementTitle}
            </p>

            <BrandScroll />
        </div>
    )
};

export default MobileEngagment;