import './DesktopLogin.css';

const DesktopLogin = () => {
    return(
        <div className='Desktop-Login'>

        </div>
    )
};

export default DesktopLogin;