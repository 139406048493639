import './DesktopAbout.css';

const DesktopAbout = () => {
    return(
        <div className='Desktop-About'>

        </div>
    )
};

export default DesktopAbout;